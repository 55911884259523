import axios from "axios"

import * as config from "../config"

// interface definition
export interface IRecipe {
    id: string,
    name: string,
    isImage: boolean,
    imageUrl?: string,
    ingredients1: string,
    ingredients2: string,
    making: string,
    comments: string,
    tag_favorite?: boolean,
    tags: string[]
}

export const FetchRecipe = async (id: string): Promise<IRecipe> => {
    const url = config.CBaseUrl + "/v1/recipe/"+id
    const result = await axios.get(url)
    return result.data as IRecipe
}

export const AddRecipe = async (): Promise<string> => {
    const url = config.CBaseUrl + "/v1/recipe"
    const result = await axios.post(url)
    return result.data.id
}

export const DeleteRecipe = async (id: string): Promise<string> => {
    const url = config.CBaseUrl + "/v1/recipe/"+id
    return await axios.delete(url)
}

export const SaveRecipe = async (id: string, data:IRecipe): Promise<string> => {
    const url = config.CBaseUrl + "/v1/recipe/"+id
    return await axios.put(url,data)
}
