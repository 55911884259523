
// External imports
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import Row from 'react-bootstrap/Row'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'

// Internal imports
import { FetchRecipe, IRecipe } from "../apis/RecipeApi"
import { DeleteRecipe } from "../apis/RecipeApi"

// The render
export const RecipeDeletePage: React.FC = () => {

    const { id } = useParams()
    const history = useHistory()
    const [recipe, setRecipe] = useState({} as IRecipe)


    // ////////////////////////////////
    // When component is first loaded
    // ////////////////////////////////
    useEffect(() => {
        const refreshData = async () => {
            setRecipe({} as IRecipe)
            try {
                const recipe: IRecipe = await FetchRecipe(id)
                setRecipe(recipe)
            } catch (err) {
                console.error(err)
            }
        }
        refreshData()
    },
        [id, history] // this is important to make sure the useEffect is called only once for the first render
    )

    const handleDelete = async () => {
        try {
            await DeleteRecipe(id)
            history.push("/")
        } catch (err) {
            console.error(err)
        }
    }

    // ////////////////////////////////
    // Render the data
    // ////////////////////////////////
    return (
        <div>
            <Row>
                <h2>מחיקת מתכון?</h2>
            </Row>
            <Row>
                <Alert variant="danger">
                    מחיקת מתכון היא סופית. לא ניתן לשחזר את המידע.
                </Alert>
            </Row>
            <Row>
                שם המתכון:&nbsp;{recipe.name}
            </Row>
            <Row className="linebreak">
                <Button variant="danger" size="sm" className="level-1-button" onClick={handleDelete}>מחיקה</Button>&nbsp;
                <Button variant="outline-primary" size="sm" className="level-1-button" href={"/"}>חזרה</Button>
            </Row>
        </div>
    )
}
