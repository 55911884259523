import React from "react"
import Navbar from "react-bootstrap/Navbar"

export const Header: React.FC = () => {

  return (
    <Navbar bg="dark" expand="lg" sticky="top">
      <Navbar.Brand href="/">
        <img
          src="/logo192.png"
          width="48"
          height="48"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
        <span className="light">
        המחברת של אפרת
        </span>
      </Navbar.Brand>
    </Navbar>
  )
}
