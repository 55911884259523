import React, { useState, useEffect, RefObject } from "react"
import Card from "react-bootstrap/Card"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import AvatarEditor from 'react-avatar-editor'

import { PostImage, DeleteImage } from "../apis/ImageApi"

const defaultImage = process.env.PUBLIC_URL + "/no-picture.jpg"
const CMaxWidth = 400
const CMaxHeight = 300

export const RecipeImage: React.FC<{ id: string, isImage: boolean, imageUrl?: string }> = (props) => {

    const [imgData, setImgData] = useState("")
    const [scale, setScale] = useState(1.0)
    const [areControlsDisabled, setAreControlsDisabled] = useState(true)
    const [info, setInfo] = useState("")
    const [danger, setDanger] = useState("")
    const [editorRef] = useState(React.createRef() as RefObject<AvatarEditor>)


    // --------------------
    // Use Effect
    // --------------------
    useEffect(() => {
        const refreshData = async () => {
            setInfo("")
            setDanger("")
            const updatedImageData = (props.isImage) ? props.imageUrl || defaultImage : defaultImage
            setImgData(updatedImageData)
        }

        refreshData()
    },
        [props] // this is important to make sure the useEffect is called only once for the first render
    )

    // --------------------
    // upload the image
    // --------------------
    const uploadFromLocalFile = async (file: File) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.addEventListener('load', async () => {
                const updatedImgData = reader.result as string

                // Get original image size
                const image = new Image()
                image.onload = () => {
                    setImgData(updatedImgData)
                    setAreControlsDisabled(false)
                }
                image.src = updatedImgData
            })
            reader.readAsDataURL(file);
        })
    }

    // --------------------
    // Delete image
    // --------------------
    const dataURLtoBlob = async (dataurl: string, fileName: string, mimeType: string): Promise<File> => {
        const result = await fetch(dataurl)
        const arrayBuffer = await result.arrayBuffer()
        return new File([arrayBuffer], fileName, { type: mimeType })
    }

    // --------------------
    // Handle events
    // --------------------
    const handleSelectFile = async (e: React.ChangeEvent<HTMLInputElement>,) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            await uploadFromLocalFile(file)
        }
    }

    const handleSaveImage = async () => {
        setInfo("")
        setDanger("")
        try {
            if (typeof(editorRef.current)==="undefined") {
                throw new Error("Image is empty")
            }
            const fileCropImage: File = await dataURLtoBlob((editorRef.current as any).getImageScaledToCanvas().toDataURL(), props.id, "image")
            await PostImage(props.id, fileCropImage)
            setInfo("שמירת התמונה הצליחה")
        } catch (err) {
            console.error(err)
            setDanger("שמירת התמונה נכשלה")
        }
    }

    const handleDeleteImage = async () => {
        setInfo("")
        setDanger("")
        setImgData(defaultImage)
        try {
            await DeleteImage(props.id)
            setInfo("מחיקת התמונה הצליחה")
        } catch (err) {
            console.error(err)
            setDanger("מחיקת התמונה לא הצליחה")
        }
    }

    const handleScaleChange = ((event: React.ChangeEvent<HTMLInputElement>) => {
        const scale = parseFloat(event.target.value)
        setScale(scale)
    })

    // wait for the page to load
    if (imgData === "") return <div></div>

    // --------------------
    // Render page
    // --------------------
    return (
        <div>
            <Row>
                {(info === "") ? "" : <Alert variant="info">{info}</Alert>}
                {(danger === "") ? "" : <Alert variant="danger">{danger}</Alert>}
            </Row>

            <Card>
                <Card.Body>
                    <AvatarEditor
                        image={imgData}
                        width={CMaxWidth}
                        height={CMaxHeight}
                        border={50}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={scale}
                        rotate={0}
                        ref={editorRef}
                    />
                </Card.Body>
                <Card.Body>
                    <Form.Control
                        name="scale"
                        type="range"
                        onChange={handleScaleChange}
                        disabled={areControlsDisabled}
                        min={'0.1'}
                        max="2"
                        step="0.01"
                        defaultValue="1"
                    />
                </Card.Body>
                <Card.Body>
                    <Form.Group>
                        <Form.File
                            id="file-loader"
                            accept="image/*"
                            className="pull-right"
                            onChange={handleSelectFile} />
                    </Form.Group>
                </Card.Body>
                <Card.Body>
                    <Button variant="primary" size="sm" onClick={handleSaveImage} disabled={areControlsDisabled} className="pull-right">שמירת תמונה</Button>&nbsp;
                    <Button variant="outline-danger" size="sm" onClick={handleDeleteImage} className="pull-right separator-button-group">מחיקת תמונה</Button>
                </Card.Body>
            </Card>
        </div>
    )
}
