import React, { useEffect } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import Container from "react-bootstrap/Container"

import "./App.css"
import { Header } from "./components/Header"
import { Footer } from "./components/Footer"
import { HomePage } from "./pages/HomePage"
import { RecipeEditPage } from "./pages/RecipeEditPage"
import { RecipeNewPage } from "./pages/RecipeNewPage"
import { RecipeDeletePage } from "./pages/RecipeDeletePage"

const App: React.FC = () => {

  // ////////////////////////////////
  // When component is first loaded
  // ////////////////////////////////
  useEffect(() => {
    console.log("admin environment:", process.env.NODE_ENV)
  },
    [] // this is important to make sure the useEffect is called only once for the first render
  )

  // ////////////////////////////////
  // Do the routing
  // ////////////////////////////////
  return (
    <div>
      <header>
        <Header />
      </header>
      <Container>
        <Router >
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/recipe-edit/:id" component={RecipeEditPage} exact />
            <Route path="/recipe-delete/:id" component={RecipeDeletePage} exact />
            <Route path="/recipe-new" component={RecipeNewPage} exact />
            <Route component={HomePage} />
          </Switch>
        </Router>
      </Container>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

export default App
