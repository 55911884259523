import * as React from "react"
import Navbar from "react-bootstrap/Navbar"

export const Footer: React.FC = () => {
  return (
    <Navbar bg="dark" expand="sm" fixed="bottom" className="footer-bar text-center">
      <Navbar.Brand>
        <span className="light footer-text">{new Date().getFullYear() + " chefef.com"}&nbsp;&copy;</span>
      </Navbar.Brand>
    </Navbar>
  )
}
