// External imports
import React, { useEffect, useState } from "react"
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import FA from 'react-fontawesome'

// Internal imports
import { FetchRecipes, IRecipeDescriptor } from "../apis/RecipesApi"

// The render
export const HomePage: React.FC = () => {

    // Setting up the State
    const [recipes, SetRecipes] = useState([] as IRecipeDescriptor[])

    // ////////////////////////////////
    // When component is first loaded: Load the recaptcha javascript
    // ////////////////////////////////
    useEffect(() => {

        refreshData()
    },
        [] // this is important to make sure the useEffect is called only once for the first render
    )

    const refreshData = async () => {
        SetRecipes([] as IRecipeDescriptor[])
        try {
            const recipes: IRecipeDescriptor[] = await FetchRecipes()
            recipes.sort((a:IRecipeDescriptor,b:IRecipeDescriptor) => {
                return 0 + ((a.name > b.name) ? 1 : -1)
            })
            SetRecipes(recipes)
        } catch (err) {
            console.error(err)
        }
    }

    const renderedRecipes = (typeof (recipes) === "undefined") ? <div></div>
        : recipes.map((enTableRowy, index) =>
            <tr key={enTableRowy.id}>
                <td className="text-right">
                    {(!enTableRowy.tag_favorite) ? <span></span> : <FA name="heart" className="heart"/>}&nbsp;
                    {enTableRowy.name}
                </td>
                <td className="text-right">
                    {(!enTableRowy.isImage) ? <span></span> : <FA name="camera"/>}
                </td>
                <td className="text-right">
                    {typeof (enTableRowy.tags) !== "undefined" ? enTableRowy.tags.length : 0}
                </td>
                <td className="text-right">
                    <Button variant="outline-primary" size="sm" href={"/recipe-edit/" + enTableRowy.id}>עדכון</Button>
                </td>
            </tr>
        )

    // ////////////////////////////////
    // Render the data
    // ////////////////////////////////
    return (
        <div>
            <Row>
                <Button variant="primary" className="level-1-button" href={"/recipe-new/"}>הוספת מתכון</Button>
            </Row>
            <Row>
                <Table size="small">
                    <thead>
                        <tr>
                            <th className="text-right">שם</th>
                            <th className="text-right">תמונה</th>
                            <th className="text-right">תגיות</th>
                            <th className="text-right">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderedRecipes}
                    </tbody>
                </Table>
            </Row>
        </div>
    )
}
