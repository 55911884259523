import axios from "axios"

import * as config from "../config"

export const PostImage = async (id: string, imageFile: File): Promise<boolean> => {
    const url = config.CBaseUrl + "/v1/image/" + id
    const formData = new FormData()
    formData.append('image', imageFile)

    await axios({
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
        url: url
    })

    return true
}

export const DeleteImage = async (id: string): Promise<boolean> => {
    const url = config.CBaseUrl + "/v1/image/" + id
    await axios.delete(url)
    return true
}
