
// External imports
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import Row from 'react-bootstrap/Row'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Form from 'react-bootstrap/Form'

// Internal imports
import { FetchRecipe, SaveRecipe, IRecipe } from "../apis/RecipeApi"
import { FetchTags, ITag } from "../apis/TagsApi"
import { RecipeImage } from "../components/RecipeImage"

// Form interface 
interface IFormInput {
    name: string
    ingredients1: string
    ingredients2: string
    making: string
    comments: string
}

// The render
export const RecipeEditPage: React.FC = () => {

    const { id } = useParams()
    const { register, handleSubmit } = useForm()

    // Setting up the State
    const [recipe, setRecipe] = useState({} as IRecipe)
    const [tags, setTags] = useState([] as ITag[])
    const [initRecipeTags, setInitRecipeTags] = useState({} as { [id: string]: boolean }) // a bit clumsy way to define a dictionary of boolean attributes
    const [newRecipeTags, setNewRecipeTags] = useState({} as { [id: string]: boolean }) // a bit clumsy way to define a dictionary of boolean attributes
    const [info, setInfo] = useState("")
    const [danger, setDanger] = useState("")

    // ////////////////////////////////
    // When component is first loaded
    // ////////////////////////////////
    useEffect(() => {
        const refreshData = async () => {
            setInfo("")
            setDanger("")
            try {
                const fetchedTags: ITag[] = await FetchTags()
                setTags(fetchedTags)

                const fetchedRecipe: IRecipe = await FetchRecipe(id)
                if (typeof (fetchedRecipe.tags) === "undefined") {
                    fetchedRecipe.tags = []
                }

                const fetchedRecipeTags: { [id: string]: boolean } = {}
                for (let element of fetchedTags) {
                    const tag = element.tag
                    fetchedRecipeTags[tag] = typeof (fetchedRecipe.tags) !== "undefined" && fetchedRecipe.tags.includes(tag)
                }
                setInitRecipeTags(fetchedRecipeTags)
                setNewRecipeTags(fetchedRecipeTags)
                setRecipe(fetchedRecipe)

            } catch (err) {
                console.error(err)
            }
        }

        refreshData()
    },
        [id] // this is important to make sure the useEffect is called only once for the first render
    )

    // ////////////////////////////////
    // handle save
    // ////////////////////////////////
    const handleSave = async (data: any) => {
        setInfo("")
        setDanger("")
        try {

            // iterate throught the form and extract checkboxes for each tag
            const updatedTags = [] as string[]

            Object.keys(newRecipeTags).forEach(key => {
                if (newRecipeTags[key]) {
                    updatedTags.push(key)
                }
            })

            // create new updated recipe record
            const updatedRecipe = recipe
            updatedRecipe.name = data.name
            updatedRecipe.ingredients1 = data.ingredients1
            updatedRecipe.ingredients2 = data.ingredients2
            updatedRecipe.making = data.making
            updatedRecipe.comments = data.comments
            updatedRecipe.tags = updatedTags

            SaveRecipe(id, updatedRecipe)
            setInfo("שמירת המתכון הצליחה")
        } catch (err) {
            console.error(err)
            setDanger("שמירת המתכון נכשלה")
        }
    }

    const handleTagChange = (tag: string) => {
        const updateRecipeTags = newRecipeTags
        updateRecipeTags[tag] = (typeof (updateRecipeTags) === "undefined") ? true : !updateRecipeTags[tag]
        setNewRecipeTags(updateRecipeTags)
    }

    // ////////////////////////////////
    // Wait for initial laod to complete
    // ////////////////////////////////
    if (typeof (recipe.name) === "undefined") {
        return <div></div>
    }

    // ////////////////////////////////
    // Render the data
    // ////////////////////////////////
    return (
        <div>
            <Row className="linebreak">
                <Button variant="primary" onClick={handleSubmit(handleSave)}>שמירת מתכון</Button>&nbsp;
                <Button variant="outline-secondary" href={"/" + id}>חזרה</Button>
            </Row>
            <Row className="linebreak">
                {(info === "") ? "" : <Alert variant="info">{info}</Alert>}
                {(danger === "") ? "" : <Alert variant="danger">{danger}</Alert>}
                &nbsp;
            </Row>
            <Tabs defaultActiveKey="details" className="linebreak">
                <Tab eventKey="details" title="פרטים">
                    <Form>
                        <Row className="linebreak">
                            <Form.Group>
                                <Form.Label className="pull-right">שם מתכון</Form.Label>
                                <Form.Control
                                    key="name"
                                    name="name"
                                    id="name"
                                    type="text"
                                    placeholder="שם מתכון"
                                    aria-label="שם מתכון"
                                    required
                                    htmlSize={80}
                                    autoComplete="off"
                                    defaultValue={recipe.name}
                                    ref={register}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group>
                                <Form.Label className="pull-right">תגיות</Form.Label>
                            </Form.Group>
                        </Row>
                        <Row>
                            {
                                typeof (tags) !== "undefined" && typeof (recipe) !== "undefined" ?
                                    tags.map(element => (
                                        <Form.Group key={"formGroup_tag_" + element.tag} className="tag">
                                            <Form.Label className="pull-right">{element.name}</Form.Label>
                                            <Form.Check
                                                key={"tag_" + element.tag}
                                                name={"tag_" + element.tag}
                                                id={"tag_" + element.tag}
                                                className="pull-right"
                                                type={'checkbox'}
                                                defaultChecked={initRecipeTags[element.tag]}
                                                onChange={() => handleTagChange(element.tag)}
                                            />
                                        </Form.Group>
                                    ))
                                    : null
                            }
                        </Row>
                        <Row>
                            <Form.Group>
                                <Form.Label className="pull-right">חומרים</Form.Label>
                                <Form.Control as="textarea"
                                    key="ingredients1"
                                    name="ingredients1"
                                    id="ingredients1"
                                    placeholder="חומרים"
                                    aria-label="חומרים"
                                    required
                                    rows={10}
                                    cols={80}
                                    defaultValue={recipe.ingredients1}
                                    ref={register}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group>
                                <Form.Label className="pull-right">חומרים (המשך)</Form.Label>
                                <Form.Control as="textarea"
                                    key="ingredients2"
                                    name="ingredients2"
                                    id="ingredients2"
                                    placeholder="חומרים"
                                    aria-label="חומרים"
                                    required
                                    rows={10}
                                    cols={80}
                                    defaultValue={recipe.ingredients2}
                                    ref={register}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group>
                                <Form.Label className="pull-right">אופן ההכנה</Form.Label>
                                <Form.Control as="textarea"
                                    key="making"
                                    name="making"
                                    id="making"
                                    placeholder="אופן ההכנה"
                                    aria-label="אופן ההכנה"
                                    required
                                    rows={10}
                                    cols={80}
                                    defaultValue={recipe.making}
                                    ref={register}
                                />
                            </Form.Group>

                        </Row>
                        <Row>
                            <Form.Group>
                                <Form.Label className="pull-right">הערות</Form.Label>
                                <Form.Control as="textarea"
                                    key="comments"
                                    name="comments"
                                    id="comments"
                                    placeholder="הערות"
                                    aria-label="הערות"
                                    required
                                    rows={10}
                                    cols={80}
                                    defaultValue={recipe.comments}
                                    ref={register}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="bottom-of-page">
                            <Form.Group>
                                <Form.Label>מחיקת מתכון</Form.Label>
                                <br />
                                <Button variant="danger" className="pull-right" href={"/recipe-delete/" + id}>מחיקה</Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </Tab>
                <Tab eventKey="image" title="תמונה">
                    <Row>
                        <h4 className="float-right" >תמונה</h4>
                    </Row>
                    <Row>
                        <RecipeImage id={recipe.id} isImage={recipe.isImage} imageUrl={recipe.imageUrl} />
                    </Row>
                </Tab>
            </Tabs>
        </div >
    )
}
