
// External imports
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

// Internal imports
import { AddRecipe } from "../apis/RecipeApi"

// The render
export const RecipeNewPage: React.FC = () => {

    const history = useHistory()

    // ////////////////////////////////
    // When component is first loaded
    // ////////////////////////////////
    useEffect(() => {
        const refreshData = async () => {
            try {
                const id: string = await AddRecipe()
                history.push("/recipe-edit/" + id)
            } catch (err) {
                console.error(err)
            }
        }

        refreshData()
    },
        [history] // this is important to make sure the useEffect is called only once for the first render
    )

    // ////////////////////////////////
    // Render the data
    // ////////////////////////////////
    return (
        <div></div>
    )
}
